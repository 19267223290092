import React from 'react';
import Timer from 'react-compound-timer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Parse } from 'parse'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import Logo from '../assets/logo.jfif'
import {
    BsFillPersonCheckFill,
    BsFillPersonFill,
    BsFillPeopleFill,
    BsArrowUp,
    BsArrowDown,
    BsFillVolumeUpFill,
    // BsDash
} from "react-icons/bs";

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            CallOffer: 0,
            CallAbandon: 0,
            AgentLogin: 0,
            CallQueue: 0,
            CallQueueList: [],
            OnlineAgentList: [],
            Logo: Logo
        };
    }

    updateCallAgentSummaries = (offer, abadon, login) => {
        this.setState({
            CallOffer: offer,
            CallAbandon: abadon,
            AgentLogin: login
        })
    }

    getCallQueue = (arr) => {
        let obj = [];
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            const object = arr[i];
            if (parseInt(object.get('ConcurrentCall')) > 0) {
                count += parseInt(object.get('ConcurrentCall'))
                obj.push({
                    ServiceCode: object.get('ServiceCode'),
                    ConcurrentCall: parseInt(object.get('ConcurrentCall')),
                    before: parseInt(object.get('ConcurrentCall')),
                    iconStatus: '',
                    status: this.compareValueText(parseInt(object.get('ConcurrentCall')), parseInt(object.get('ConcurrentCall')))
                })
            }

        }

        this.setState({
            CallQueue: count,
            CallQueueList: obj,
        })
    }

    getOnlineAgentList = (arr) => {
        let obj = [];
        for (let i = 0; i < arr.length; i++) {
            const object = arr[i];
            // console.log(JSON.stringify(object))
            obj.push({
                AgentID: object.get('AgentID'),
                AgentName: object.get('AgentName'),
                AgentStatus: object.get('AgentStatus'),
                AgentTime: Date.now() - Date.parse(object.get('startedAt'))
            })

        }

        this.setState({
            OnlineAgentList: obj,
        })
    }

    updateOnlineAgentList = (id, name, status, login, time) => {
        let arr = this.state.OnlineAgentList;

        if (this.searchAgent(id) === true) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].AgentID === id) {
                    if (parseInt(login) === 1) {
                        arr[i].AgentID = id;
                        arr[i].AgentName = name;
                        arr[i].AgentStatus = status;
                        arr[i].AgentTime = Date.now() - Date.parse(time);
                    } else {
                        arr.splice(i, 1)
                    }
                }
            }
        } else {
            arr.push({
                AgentID: id,
                AgentName: name,
                AgentStatus: status,
                AgentTime: Date.now() - Date.parse(time)
            })
        }

        this.setState({
            OnlineAgentList: arr,
        })
    }

    onlineTimeStyle = (nornal, warning, timeValue) => {
        let time_normal = nornal * 60000,
            time_warning = warning * 60000;

            if(timeValue < time_normal){
                return "text-dark"
            }else if(timeValue < time_warning){
                return "text-dark"
            }else{
                return "text-dark"
            }

    }

    searchArray = (nameKey) => {
        let myArray = this.state.CallQueueList;
        console.log(myArray.length)
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].ServiceCode === nameKey) {
                return true;
            }
        }
    }

    searchAgent = (id) => {
        let myArray = this.state.OnlineAgentList;
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].AgentID === id) {
                return true;
            }
        }
    }

    updateCallQueue = (ServiceCode, ConcurrentCall) => {
        let arr = this.state.CallQueueList;
        let count = this.state.CallQueue;

        if (this.searchArray(ServiceCode) === true) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].ServiceCode === ServiceCode) {
                    if (ConcurrentCall > 0) {
                        arr[i].before = arr[i].ConcurrentCall;
                        count -= arr[i].ConcurrentCall;
                        count += parseInt(ConcurrentCall);
                        arr[i].ConcurrentCall = ConcurrentCall;
                        arr[i].iconStatus = this.compareValue(arr[i].before, ConcurrentCall);
                        arr[i].status = this.compareValueText(arr[i].before, ConcurrentCall);
                    } else {
                        count -= arr[i].ConcurrentCall;
                        arr.splice(i, 1)
                    }

                }
            }
        } else {
            count += parseInt(ConcurrentCall)
            arr.push({
                ServiceCode: ServiceCode,
                ConcurrentCall: ConcurrentCall,
                before: ConcurrentCall,
                iconStatus: <BsArrowUp className="text-danger" />,
                status: this.compareValueText(0, parseInt(ConcurrentCall))
            })
        }

        this.setState({
            CallQueue: count,
            CallQueueList: arr,
        })

    }

    createCallQueue = (ServiceCode, ConcurrentCall) => {
        let arr = this.state.CallQueueList;
        let count = this.state.CallQueue;

        count += parseInt(ConcurrentCall)
        arr.push({
            ServiceCode: ServiceCode,
            ConcurrentCall: ConcurrentCall,
            before: ConcurrentCall,
            iconStatus: <BsArrowUp className="text-danger" />,
            status: this.compareValueText(0, parseInt(ConcurrentCall))
        })

        this.setState({
            CallQueue: count,
            CallQueueList: arr,
        })

    }

    deleteCallQueue = (ServiceCode, ConcurrentCall) => {
        let arr = this.state.CallQueueList;
        let count = this.state.CallQueue;

        if (this.searchArray(ServiceCode) === true) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].ServiceCode === ServiceCode) {
                    count -= arr[i].ConcurrentCall;
                    arr.splice(i, 1)
                }
            }
        }

        this.setState({
            CallQueue: count,
            CallQueueList: arr,
        })

    }

    compareValue = (before, current) => {
        console.log('before:' + before)
        console.log('current:' + current)
        if (parseInt(before) > parseInt(current)) {
            return <BsArrowDown className="text-success" />
        } else if (parseInt(before) < parseInt(current)) {
            return <BsArrowUp className="text-danger" />
        } else if (parseInt(before) === parseInt(current)) {
            return ''
        }
    }

    compareValueText = (before, current) => {
        if (parseInt(before) > parseInt(current)) {
            return <span className="text-success">decrease -{parseInt(before) - parseInt(current)}</span>
        } else if (parseInt(before) < parseInt(current) || parseInt(before) === 0) {
            return <span className="text-danger">increase  +{parseInt(current) - parseInt(before)}</span>
        } else if (parseInt(before) === parseInt(current)) {
            return <span className="text-secondary">No change</span>
        }

    }

    queueStyle = (i) => {
        if (parseInt(i) === 1) {
            return "badge bg-success text-light";
        } else if (parseInt(i) === 2) {
            return "badge bg-warning text-dark";
        } else if (parseInt(i) > 2) {
            return "badge bg-danger text-light";
        }
    }

    queueCountStyle = (i) => {
        if (parseInt(i) === 1) {
            return "text-center border-success border shadow rounded text-light bg-success";
        } else if (parseInt(i) === 2) {
            return "text-center border-warning border shadow rounded text-dark bg-warning";
        } else if (parseInt(i) > 2) {
            return "text-center border-danger border shadow rounded text-light bg-danger";
        }
    }

    get_data = async () => {

        Parse.initialize("fujitsuapi", "fujitsuapi", "fujitsuapi");
        Parse.serverURL = "http://128.199.188.223:4000/api";
        Parse.initialize(
            'fujitsuapi',
            'fujitsuapi', // Application ID
            'fujitsuapi' // Javascript key
        );

        let CallAgentSummaries = Parse.Object.extend("CallAgentSummaries");
        let queryCallAgentSummaries = new Parse.Query(CallAgentSummaries);

        let CallQueues = Parse.Object.extend('CallQueues');
        let queryCallQueues = new Parse.Query(CallQueues);

        let OnlineAgentLists = Parse.Object.extend('OnlineAgentLists');
        let queryOnlineAgentLists = new Parse.Query(OnlineAgentLists);

        Parse.liveQueryServerURL = await "ws://128.199.188.223:4001";

        // Call Agent Summaries
        const summaryGet = await queryCallAgentSummaries.find();
        this.updateCallAgentSummaries(summaryGet[0].get('CallOffer'), summaryGet[0].get('CallAbandon'), summaryGet[0].get('AgentLogin'))
        let summary = await queryCallAgentSummaries.subscribe();
        // summary.on('open', () => {
        //     console.log('subscription opened Call Agent Summaries');
        // });
        summary.on('update', async (object) => {
            console.log('object updated summary');
            this.updateCallAgentSummaries(object.get('CallOffer'), object.get('CallAbandon'), object.get('AgentLogin'))
        });


        // Call Queues
        const queuesGet = await queryCallQueues.find();
        this.getCallQueue(queuesGet)
        let queueUpdate = await queryCallQueues.subscribe();
        // queueUpdate.on('open', () => {
        //     console.log('subscription opened Call Queues');
        // });
        queueUpdate.on('update', async (object) => {
            console.log('object updated Call Queues');
            this.updateCallQueue(object.get('ServiceCode'), object.get('ConcurrentCall'))
        });
        queueUpdate.on('create', async (object) => {
            console.log('object create Call Queues');
            this.createCallQueue(object.get('ServiceCode'), object.get('ConcurrentCall'))
        })
        queueUpdate.on('delete', async (object) => {
            console.log('object delete Call Queues');
            this.deleteCallQueue(object.get('ServiceCode'), object.get('ConcurrentCall'))
        })

        // Online Agent Lists
        const onlineAgentList = await queryOnlineAgentLists.find();
        this.getOnlineAgentList(onlineAgentList)
        let agent = await queryOnlineAgentLists.subscribe();
        // agent.on('open', () => {
        //     console.log('subscription opened agent list');
        // });
        agent.on('update', async (object) => {
            console.log('object updated agent list');
            this.updateOnlineAgentList(object.get('AgentID'),
                object.get('AgentName'),
                object.get('AgentStatus'),
                object.get('IsLogin'),
                object.get('startedAt')
            );
        });
    }

    componentDidMount() {
        this.get_data()
    }

    render() {

        return (
            <div className="App">
                <Container className="mt-3">
                    <Row>
                        <Col xs={1} className="mt-1">
                            <img src={this.state.Logo} alt="Fujitsu" className="mr-2 rounded-circle shadow img-fluid" />

                        </Col>
                        <Col className="text-uppercase ml-0 pl-0">
                            <h1 className=" pb-0 mb-0">
                                Wallboard
                            </h1>
                            <small className="badge bg-secondary text-light">Fujitsu (Thailand) Co., Ltd.</small>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-3">
                    <Row>
                        <Col md={4}>
                            <div className="border rounded shadow-sm bg-success pt-3 pb-3">
                                <Row>
                                    <Col md={12} className="text-center text-light pt-2 pb-2">Call Offer</Col>
                                    <Col md={6} className="text-right">
                                        <BsFillPersonCheckFill size="50" className="text-light" />
                                    </Col>
                                    <Col md={6}>
                                        <h1 className="text-light">{this.state.CallOffer}</h1>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="border rounded shadow-sm bg-warning pt-3 pb-3">
                                <Row>
                                    <Col md={12} className="text-center text-dark pt-2 pb-2">Call Abandon</Col>
                                    <Col md={6} className="text-right">
                                        <BsFillVolumeUpFill size="50" className="text-dark" />
                                    </Col>
                                    <Col md={6}>
                                        <h1 className="text-darj">{this.state.CallAbandon}</h1>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="border rounded shadow-sm bg-info pt-3 pb-3">
                                <Row>
                                    <Col md={12} className="text-center text-light pt-2 pb-2">Agent Login</Col>
                                    <Col md={6} className="text-right">
                                        <BsFillPeopleFill size="50" className="text-light" />
                                    </Col>
                                    <Col md={6}>
                                        <h1 className="text-light">{this.state.AgentLogin}</h1>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="shadow-lg mt-3 rounded bg-light mt-2 mb-2 pl-3 pr-3 pt-1 pb-1">
                    <Row className="mt-4 mb-4">
                        <Col md={12}>
                            <h2 className="text-uppercase">Call Queue</h2>
                        </Col>
                        <Col md={12}>
                            <div className="border rounded shadow-sm p-3" style={{ backgroundColor: '#eee' }}>

                                <Row>
                                    <Col md={8}>

                                        <Row>
                                            {this.state.CallQueueList.map((obj, idx) => (
                                                <Col md={6} key={idx}>
                                                    <Alert variant={
                                                        parseInt(obj.ConcurrentCall) === 1 ? 'success'
                                                            : parseInt(obj.ConcurrentCall) === 2 ? 'warning'
                                                                : 'danger'} >
                                                        <Row>

                                                            <Col md={9} >
                                                                <h5 className="mb-0">{obj.ServiceCode}</h5>
                                                                <strong style={{ fontSize: '10px' }}>
                                                                    <span>{obj.iconStatus}</span>
                                                                    {obj.status}
                                                                </strong>
                                                            </Col>

                                                            <Col className="text-right">
                                                                <h3>
                                                                    {/* {this.compareValue(obj.before, obj.ConcurrentCall)} */}

                                                                    <span className={this.queueStyle(obj.ConcurrentCall)}>
                                                                        {obj.ConcurrentCall}
                                                                    </span>
                                                                </h3>
                                                            </Col>
                                                        </Row>
                                                    </Alert>
                                                </Col>
                                            ))}

                                        </Row>


                                    </Col>
                                    <Col md={4}>
                                        <div className={this.queueCountStyle(this.state.CallQueue)}
                                            style={{ fontSize: '150px' }}>
                                            {this.state.CallQueue}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-3">
                    <Row>
                        <Col md={3}>
                            <table className="table table-sm border shadow-sm bg-light">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="bg-success text-light text-center">Idle Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OnlineAgentList.map((obj, idx) => (
                                        (parseInt(obj.AgentStatus) === 1) &&
                                        <tr key={idx}>
                                            <td>
                                                <small className="text-secondary"><BsFillPersonFill /> {obj.AgentName}</small>
                                            </td>
                                            <td className="text-right">
                                                <small className={this.onlineTimeStyle(5,10,obj.AgentTime)}>
                                                    <Timer
                                                        initialTime={obj.AgentTime}
                                                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                    >
                                                        {() => (
                                                            <React.Fragment>
                                                                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                                                            </React.Fragment>
                                                        )}
                                                    </Timer>
                                                </small>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                        <Col md={3}>
                            <table className="table table-sm border shadow-sm bg-light">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="bg-info text-light text-center">Active Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OnlineAgentList.map((obj, idx) => (
                                        (parseInt(obj.AgentStatus) === 2) &&
                                        <tr key={idx}>
                                            <td>
                                                <small className="text-secondary"><BsFillPersonFill /> {obj.AgentName}</small>
                                            </td>
                                            <td className="text-right">
                                                <small className={this.onlineTimeStyle(5,10,obj.AgentTime)}>
                                                    <Timer
                                                        initialTime={obj.AgentTime}
                                                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                    >
                                                        {() => (
                                                            <React.Fragment>
                                                                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                                                            </React.Fragment>
                                                        )}
                                                    </Timer>
                                                </small>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                        <Col md={3}>
                            <table className="table table-sm border shadow-sm bg-light">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="bg-warning text-center">Wrap Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OnlineAgentList.map((obj, idx) => (
                                        (parseInt(obj.AgentStatus) === 3) &&
                                        <tr key={idx}>
                                            <td>
                                                <small className="text-secondary"><BsFillPersonFill /> {obj.AgentName}</small>
                                            </td>
                                            <td className="text-right">
                                                <small className={this.onlineTimeStyle(5,10,obj.AgentTime)}>
                                                    <Timer
                                                        initialTime={obj.AgentTime}
                                                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                    >
                                                        {() => (
                                                            <React.Fragment>
                                                                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                                                            </React.Fragment>
                                                        )}
                                                    </Timer>
                                                </small>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                        <Col md={3}>
                            <table className="table table-sm border shadow-sm bg-light">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="bg-danger text-light text-center">Not Ready Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.OnlineAgentList.map((obj, idx) => (
                                        (parseInt(obj.AgentStatus) === 4) &&
                                        <tr key={idx}>
                                            <td>
                                                <small className="text-secondary"><BsFillPersonFill /> {obj.AgentName}</small>
                                            </td>
                                            <td className="text-right">
                                                <small className={this.onlineTimeStyle(5,10,obj.AgentTime)}>
                                                    <Timer
                                                        initialTime={obj.AgentTime}
                                                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                    >
                                                        {() => (
                                                            <React.Fragment>
                                                                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                                                            </React.Fragment>
                                                        )}
                                                    </Timer>
                                                </small>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Col>

                    </Row>
                </Container>
            </div>

        )
    }
}
